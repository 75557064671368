import firebase from 'firebase';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { serialize, deserialize } from '../../utils/db';

const MODEL = 'courses';

export function list(
  ownerId,
  callback,
  params = { subscribe: false }
) {
  const db = firebase.firestore();
  const { subscribe } = params;

  if (subscribe) {
    return db
      .collection(MODEL)
      .where('ownerId', '==', ownerId)
      .onSnapshot(snapshot => {
        const results = snapshot.docs.map(doc => {
          return deserialize(doc.exists ? doc.data() : null);
        });
        callback(results);
      });
  } else {
    return db
      .collection(MODEL)
      .where('ownerId', '==', ownerId)
      .get()
      .then(snapshot => {
        const results = snapshot.docs.map(doc => {
          return deserialize(doc.exists ? doc.data() : null);
        });
        callback(results);
      });
  }
}

export function get(id, callback, params = { subscribe: false }) {
  const db = firebase.firestore();
  const { subscribe } = params;

  if (subscribe) {
    return db
      .collection(MODEL)
      .doc(id)
      .onSnapshot(doc => {
        const result = deserialize(doc.exists ? doc.data() : null);
        console.log('GET', doc.data(), result);
        callback(result);
      });
  } else {
    return db
      .collection(MODEL)
      .doc(id)
      .get()
      .then(doc => {
        const result = deserialize(doc.exists ? doc.data() : null);
        callback(result);
      });
  }
}

export function create(entity, id = uuid()) {
  const db = firebase.firestore();
  const data = {
    ...entity,
    id,
  };

  return db
    .collection(MODEL)
    .doc(id)
    .set(serialize(data))
    .then(() => data);
}

export function update(entity) {
  const db = firebase.firestore();

  const data = {
    ...entity,
    updatedAt: moment(),
  };

  return db
    .collection(MODEL)
    .doc(entity.id)
    .set(serialize(data))
    .then(() => data);
}

export function remove(id) {
  const db = firebase.firestore();

  return db
    .collection(MODEL)
    .doc(id)
    .delete();
}

export default {
  list,
  create,
  update,
  get,
  remove,
};
