import React from 'react';
import Loader from 'react-loaders';
import cx from 'classnames';
import './style.scss';

export default class LoadingIndicator extends React.Component {
  static defaultProps = {
    type: 'ball-rotate',
    color: '#f7b924',
  };

  render() {
    const { className, color, type, block } = this.props;

    return (
      <Loader className={cx('LoadingIndicator', className, { block })} color={color} type={type} />
    );
  }
}
