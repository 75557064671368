import React from 'react';
import moment from 'moment';
import { every, isEmpty, isNumber, isNil, cloneDeep } from 'lodash';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

const LOCAL_DATE_ONE_DIGIT_FORMAT = 'YYYY-M-DTH:m:s.SSSSZ';
const TIMESTAMP_ONE_DIGIT_FORMAT = 'YYYY-M-DTH:m:s.SSSSZ';

export function createUtcMoment(year, month, day, hour = 0, min = 0, sec = 0) {
  return moment(`${year}-${month}-${day}T${hour}:${min}:${sec}.000Z`, TIMESTAMP_ONE_DIGIT_FORMAT);
}

export function createUtcDateString(year, month, day, hour = 0, min = 0, sec = 0) {
  return createUtcMoment(year, month, day, hour, min, sec).toISOString();
}

export function createLocalMoment(year, month, day, hour = 0, min = 0, sec = 0) {
  return moment(`${year}-${month}-${day} ${hour}:${min}:${sec}`, LOCAL_DATE_ONE_DIGIT_FORMAT);
}

export function createLocalDateString(year, month, day, hour = 0, min = 0, sec = 0) {
  return createLocalMoment(year, month, day, hour, min, sec).toISOString();
}

export function convertAllDatesToString(data, clone = true) {
  if (isNil(data)) return data;
  const dataType = typeof data;

  if (moment.isMoment(data)) {
    return data.toISOString();
  } else if (data instanceof Date) {
    return moment(data).toISOString();
  } else if (dataType === 'object') {
    const clonedData = clone ? cloneDeep(data) : data;
    if (Array.isArray(data)) {
      return clonedData.map(i => convertAllDatesToString(i, false));
    } else {
      Object.keys(clonedData).forEach(key => {
        clonedData[key] = convertAllDatesToString(clonedData[key], false);
      });
      return clonedData;
    }
  }

  return data;
}

export function isEmptyObject(object) {
  return every(object, isEmpty);
}

export function withTransition(WrappedComponent) {
  class Component extends React.Component {
    render() {
      return (
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <WrappedComponent {...this.props} />
        </ReactCSSTransitionGroup>
      );
    }
  }

  return Component;
}

export function getAvatarImage(data) {
  if (isEmpty(data) || !isNumber(data.avatar)) {
    return null;
  }

  return `http://www.levelupskill.me/img/avatar/${data.id}.jpg?${data.avatar}`;
}
