import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Routes } from '../../routes';
import Route from '../Route';

class AppMain extends React.Component {
  render() {
    const { history } = this.props;
    const { pathname } = window.location;
    const { authenticated: routes } = Routes;

    if (pathname === '/') {
      history.push('/dashboard');
    }

    return (
      <Fragment>
        {Object.keys(routes).map(path => {
          const route = routes[path];
          return <Route key={path} authenticated path={path} component={route.component} />;
        })}
        <ToastContainer />
      </Fragment>
    );
  }
}

export default withRouter(AppMain);
