export default class APIError extends Error {
  constructor(reason, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APIError);
    }

    this.name = 'APIError';
    // Custom debugging information
    this.reason = reason;
    this.date = new Date();
  }
}