import firebase from 'firebase';
import { isEmpty } from 'lodash';
import { v4 as uuid } from 'uuid';

const MODEL = 'refs';

export function deserialize(data) {
  if (isEmpty(data)) {
    return {};
  }

  const result = {};
  data.forEach(doc => {
    const item = doc.data();
    result[item.id] = item.data;
  });
  return result;
}

export function seed(callback) {
  list(data => {
    const promises = [];

    if (isEmpty(data.courseVisibilities)) {
      promises.push(
        create({ id: 'courseVisibilities', data: ['Private', 'Public'] }, 'courseVisibilities')
      );
    }
    if (isEmpty(data.courseStatuses)) {
      promises.push(
        create(
          { id: 'courseStatuses', data: ['Inactive', 'Active'] },
          'courseStatuses'
        )
      );
    }
    if (isEmpty(data.courseCapacities)) {
      promises.push(
        create(
          { id: 'courseCapacities', data: ['Uncapped', 'Capped'] },
          'courseCapacities'
        )
      );
    }

    if (isEmpty(data.userRoles)) {
      promises.push(
        create({ id: 'userRoles', data: ['Student', 'Guardian', 'Instructor', 'Administrator'] }, 'userRoles')
      );
    }
    if (isEmpty(data.userStatuses)) {
      promises.push(
        create(
          { id: 'userStatuses', data: ['Inactive', 'Active', 'Deactivated'] },
          'userStatuses'
        )
      );
    }

    if (isEmpty(data.postTags)) {
      promises.push(
        create({ id: 'postTags', data: ['Online', 'On-Premise'] }, 'postTags')
      );
    }
    if (isEmpty(data.postCategories)) {
      promises.push(
        create(
          {
            id: 'postCategories',
            data: [
              'Science',
              'Technology',
              'Engineering',
              'Art',
              'Mathematics',
              'Health & Fitness',
            ],
          },
          'postCategories'
        )
      );
    }
    if (isEmpty(data.postStatuses)) {
      promises.push(
        create(
          { id: 'postStatuses', data: ['Draft', 'Published', 'Unpublished'] },
          'postStatuses'
        )
      );
    }

    Promise.all(promises).then(callback);
  });
}

export function list(callback, params = { subscribe: false }) {
  const db = firebase.firestore();

  if (params.subscribe) {
    return db.collection(MODEL).onSnapshot(snapshot => {
      callback(deserialize(snapshot.docs));
    });
  } else {
    return db
      .collection(MODEL)
      .get()
      .then(snapshot => {
        callback(deserialize(snapshot.docs));
      });
  }
}

export function create(entity, id = uuid()) {
  const db = firebase.firestore();
  return db
    .collection(MODEL)
    .doc(id)
    .set(entity);
}

export function listPostCategory(callback, params = { subscribe: false }) {
  const db = firebase.firestore();

  if (params.subscribe) {
    return db
      .collection(MODEL)
      .doc('postCategories')
      .onSnapshot(doc => {
        callback(doc.exists ? doc.data() : null);
      });
  } else {
    return db
      .collection(MODEL)
      .doc('postCategories')
      .get()
      .then(doc => {
        callback(doc.exists ? doc.data() : null);
      });
  }
}

export function addPostCategory(postCategory) {
  return new Promise((resolve, reject) => {
    listPostCategory(postCategories => {
      if (!postCategories.data.includes(postCategory)) {
        const db = firebase.firestore();
        db.collection(MODEL)
          .doc('postCategories')
          .set({
            ...postCategories,
            data: [...postCategories.data, postCategory],
          })
          .then(resolve)
          .catch(reject);
      }
    });
  });
}

export function listPostTag(callback, params = { subscribe: false }) {
  const db = firebase.firestore();

  if (params.subscribe) {
    return db
      .collection(MODEL)
      .doc('postTags')
      .onSnapshot(doc => {
        callback(doc.exists ? doc.data() : null);
      });
  } else {
    return db
      .collection(MODEL)
      .doc('postTags')
      .get()
      .then(doc => {
        callback(doc.exists ? doc.data() : null);
      });
  }
}

export function addPostTag(postTag) {
  return new Promise((resolve, reject) => {
    listPostTag(postTags => {
      if (!postTags.data.includes(postTag)) {
        const db = firebase.firestore();
        db.collection(MODEL)
          .doc('postTags')
          .set({
            ...postTags,
            data: [...postTags.data, postTag],
          })
          .then(resolve)
          .catch(reject);
      }
    });
  });
}

export function listUserRole(callback, params = { subscribe: false }) {
  const db = firebase.firestore();

  if (params.subscribe) {
    return db
      .collection(MODEL)
      .doc('userRoles')
      .onSnapshot(doc => {
        callback(doc.exists ? doc.data() : null);
      });
  } else {
    return db
      .collection(MODEL)
      .doc('userRoles')
      .get()
      .then(doc => {
        callback(doc.exists ? doc.data() : null);
      });
  }
}

export function addUserRole(userRole) {
  return new Promise((resolve, reject) => {
    listPostCategory(userRoles => {
      if (!userRoles.data.includes(userRole)) {
        const db = firebase.firestore();
        db.collection(MODEL)
          .doc('userRoles')
          .set({
            ...userRoles,
            data: [...userRoles.data, userRole],
          })
          .then(resolve)
          .catch(reject);
      }
    });
  });
}

export default {
  list,
  create,
  seed,
  addPostCategory,
  addPostTag,
  addUserRole,
};
