import React from 'react';
import cx from 'classnames';
import { Button as BaseButton } from 'reactstrap';
import { isNil } from 'lodash';

import './style.scss';

export default class Button extends React.Component {
  static defaultProps = {
    pill: true,
  };

  render() {
    const {
      type,
      size,
      gradient,
      icon,
      iconOnly,
      circle,
      square,
      prominent,
      onClick,
      label,
      children,
      className,
      disabled,
    } = this.props;

    let {
      color,
      shadow,
      wide,
      pill,
      hoverShine,
      outline,
      link,
    } = this.props;

    if (pill) {
      switch (type) {
        case 'primary':
          if (isNil(wide)) wide = true;
          if (isNil(hoverShine)) hoverShine = true;
          break;
          
        case 'secondary':
          if (isNil(outline)) outline = true;
          break;
          
        case 'link':
          if (isNil(link)) link = true;
          break;

        default: break;
      }
    }

    if (disabled) {
      color = 'light';
    }

    return (
      <BaseButton
        color={color || type}
        outline={outline}
        disabled={disabled}
        className={cx('Button', className, {
          [`btn-gradient-${gradient}`]: gradient,
          [`btn-size-${size}`]: size,
          'btn-wide': wide,
          'btn-pill': pill,
          'btn-shadow': prominent || shadow,
          'btn-hover-shine': prominent || hoverShine,
          'btn-icon-only': iconOnly,
          'btn-circle': circle,
          'btn-square': square,
          'btn-link': link,
        })}
        onClick={onClick}
      >
        {icon && <i className={icon}></i>}
        {!iconOnly && (label || children)}
      </BaseButton>
    );
  }
}
