import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Button,
  Nav,
  NavItem,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
  Input,
} from 'reactstrap';

import {
  faSearch,
} from '@fortawesome/free-solid-svg-icons';

import avatar1 from '../../../assets/utils/images/avatars/1.jpg';
import avatar2 from '../../../assets/utils/images/avatars/2.jpg';
import avatar3 from '../../../assets/utils/images/avatars/3.jpg';
import avatar4 from '../../../assets/utils/images/avatars/4.jpg';
import avatar5 from '../../../assets/utils/images/avatars/5.jpg';
import avatar6 from '../../../assets/utils/images/avatars/8.jpg';
import avatar7 from '../../../assets/utils/images/avatars/9.jpg';
import avatar8 from '../../../assets/utils/images/avatars/10.jpg';

class ChatSidebar extends React.Component {
  render() {
    return (
      <div className="ChatSidebar app-inner-layout__sidebar">
        <div className="app-inner-layout__sidebar-header">
          <Nav vertical>
            <NavItem className="pt-4 pl-3 pr-3 pb-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <div className="input-group-text">
                    <FontAwesomeIcon icon={faSearch} />
                  </div>
                </InputGroupAddon>
                <Input placeholder="Search..." />
              </InputGroup>
            </NavItem>
            <NavItem className="nav-item-header"><Trans>Online</Trans></NavItem>
          </Nav>
        </div>
        <Nav vertical>
          <NavItem>
            <DropdownItem>
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left mr-3">
                    <div className="avatar-icon-wrapper">
                      <div className="badge badge-bottom badge-success badge-dot badge-dot-lg" />
                      <div className="avatar-icon">
                        <img src={avatar1} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="widget-content-left">
                    <div className="widget-heading">Alina Mcloughlin</div>
                    <div className="widget-subheading">
                      Aenean vulputate eleifend tellus.
                    </div>
                  </div>
                </div>
              </div>
            </DropdownItem>
          </NavItem>
          <NavItem>
            <DropdownItem active>
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left mr-3">
                    <div className="avatar-icon-wrapper">
                      <div className="badge badge-bottom badge-success badge-dot badge-dot-lg" />
                      <div className="avatar-icon">
                        <img src={avatar2} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="widget-content-left">
                    <div className="widget-heading">Chad Evans</div>
                    <div className="widget-subheading">
                      Vivamus elementum semper nisi.
                    </div>
                  </div>
                </div>
              </div>
            </DropdownItem>
          </NavItem>
          <NavItem>
            <DropdownItem>
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left mr-3">
                    <div className="avatar-icon-wrapper">
                      <div className="badge badge-bottom badge-success badge-dot badge-dot-lg" />
                      <div className="avatar-icon">
                        <img src={avatar3} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="widget-content-left">
                    <div className="widget-heading">Ella-Rose Henry</div>
                    <div className="widget-subheading">
                      Etiam sit amet orci eget eros faucibus
                    </div>
                  </div>
                </div>
              </div>
            </DropdownItem>
          </NavItem>
          <NavItem>
            <DropdownItem>
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left mr-3">
                    <div className="avatar-icon-wrapper">
                      <div className="badge badge-bottom badge-success badge-dot badge-dot-lg" />
                      <div className="avatar-icon">
                        <img src={avatar4} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="widget-content-left">
                    <div className="widget-heading">Ruben Tillman</div>
                    <div className="widget-subheading">
                      Lorem ipsum dolor sit amet, consectetuer
                    </div>
                  </div>
                </div>
              </div>
            </DropdownItem>
          </NavItem>
          <NavItem>
            <DropdownItem>
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left mr-3">
                    <div className="avatar-icon-wrapper">
                      <div className="badge badge-bottom badge-success badge-dot badge-dot-lg" />
                      <div className="avatar-icon">
                        <img src={avatar3} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="widget-content-left">
                    <div className="widget-heading">Ella-Rose Henry</div>
                    <div className="widget-subheading">
                      Etiam sit amet orci eget eros faucibus
                    </div>
                  </div>
                </div>
              </div>
            </DropdownItem>
          </NavItem>
          <NavItem>
            <DropdownItem>
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left mr-3">
                    <div className="avatar-icon-wrapper">
                      <div className="badge badge-bottom badge-success badge-dot badge-dot-lg" />
                      <div className="avatar-icon">
                        <img src={avatar4} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="widget-content-left">
                    <div className="widget-heading">Ruben Tillman</div>
                    <div className="widget-subheading">
                      Lorem ipsum dolor sit amet, consectetuer
                    </div>
                  </div>
                </div>
              </div>
            </DropdownItem>
          </NavItem>
        </Nav>
        <div className="app-inner-layout__sidebar-footer pb-3">
          <Nav vertical>
            <NavItem className="nav-item-divider" />
            <NavItem className="nav-item-header"><Trans>Offline</Trans></NavItem>
            <NavItem className="text-center p-2">
              <div className="avatar-wrapper avatar-wrapper-overlap">
                <div className="avatar-icon-wrapper">
                  <div className="badge badge-bottom badge-danger badge-dot badge-dot-lg" />
                  <div className="avatar-icon rounded">
                    <img src={avatar1} alt="" />
                  </div>
                </div>
                <div className="avatar-icon-wrapper">
                  <div className="badge badge-bottom badge-danger badge-dot badge-dot-lg" />
                  <div className="avatar-icon rounded">
                    <img src={avatar2} alt="" />
                  </div>
                </div>
                <div className="avatar-icon-wrapper">
                  <div className="badge badge-bottom badge-danger badge-dot badge-dot-lg" />
                  <div className="avatar-icon rounded">
                    <img src={avatar3} alt="" />
                  </div>
                </div>
                <div className="avatar-icon-wrapper">
                  <div className="badge badge-bottom badge-danger badge-dot badge-dot-lg" />
                  <div className="avatar-icon rounded">
                    <img src={avatar4} alt="" />
                  </div>
                </div>
                <div className="avatar-icon-wrapper">
                  <div className="badge badge-bottom badge-danger badge-dot badge-dot-lg" />
                  <div className="avatar-icon rounded">
                    <img src={avatar5} alt="" />
                  </div>
                </div>
                <div className="avatar-icon-wrapper">
                  <div className="badge badge-bottom badge-danger badge-dot badge-dot-lg" />
                  <div className="avatar-icon rounded">
                    <img src={avatar6} alt="" />
                  </div>
                </div>
                <div className="avatar-icon-wrapper">
                  <div className="badge badge-bottom badge-danger badge-dot badge-dot-lg" />
                  <div className="avatar-icon rounded">
                    <img src={avatar7} alt="" />
                  </div>
                </div>
                <div className="avatar-icon-wrapper">
                  <div className="badge badge-bottom badge-danger badge-dot badge-dot-lg" />
                  <div className="avatar-icon rounded">
                    <img src={avatar8} alt="" />
                  </div>
                </div>
                <div className="avatar-icon-wrapper avatar-icon-add">
                  <div className="avatar-icon rounded">
                    <i>+</i>
                  </div>
                </div>
              </div>
            </NavItem>
            <NavItem className="nav-item-btn text-center">
              <Button size="sm" className="btn-wide btn-pill" color="success">
                <Trans>Send Offline Message</Trans>
              </Button>
            </NavItem>
          </Nav>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ChatSidebar);
