import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Routes } from '../../routes';
import Route from '../Route';

class AppAuth extends React.Component {
  render() {
    const { history } = this.props;
    const { pathname } = window.location;
    const { public: routes } = Routes;

    if (pathname === '/') {
      history.push('/login');
    }

    return (
      <Fragment>
        {Object.keys(routes).map(path => {
          const route = routes[path];
          return <Route key={path} public path={path} component={route.component} />;
        })}
        <ToastContainer />
      </Fragment>
    );
  }
};

export default withRouter(AppAuth);
