import firebase from 'firebase';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { serialize, deserialize } from '../../utils/db';
import axios from 'axios';

import APIError from '../../errors/APIError';

const MODEL = 'users';

export function list(callback, params = { subscribe: false, model: MODEL }) {
  const db = firebase.firestore();
  const { subscribe, model } = params;

  if (subscribe) {
    return db.collection(model).onSnapshot(snapshot => {
      const results = snapshot.docs.map(doc => {
        return deserialize(doc.exists ? doc.data() : null);
      });
      callback(results);
    });
  } else {
    return db
      .collection(model)
      .get()
      .then(snapshot => {
        const results = snapshot.docs.map(doc => {
          return deserialize(doc.exists ? doc.data() : null);
        });
        callback(results);
      });
  }
}

export function get(id, callback, params = { subscribe: false, model: MODEL }) {
  const db = firebase.firestore();
  const { subscribe, model } = params;

  if (subscribe) {
    return db
      .collection(model)
      .doc(id)
      .onSnapshot(doc => {
        const result = deserialize(doc.exists ? doc.data() : null);
        callback(result);
      });
  } else {
    return db
      .collection(model)
      .doc(id)
      .get()
      .then(doc => {
        const result = deserialize(doc.exists ? doc.data() : null);
        callback(result);
      });
  }
}

export function getOnceByEmail(email, params = { model: MODEL }) {
  const db = firebase.firestore();
  const { model } = params;

  return new Promise((resolve, reject) => {
    db.collection(model)
      .where('email', '==', email)
      .get()
      .then(user => {
        if (user.size > 0) {
          resolve(deserialize(user.docs[0].data()));
        } else {
          reject(new APIError('emailNotFound', 'Email not found'));
        }
      })
      .catch(reject);
  });
}

export function create(entity, id = uuid(), params = { model: MODEL }) {
  const db = firebase.firestore();
  const { model } = params;
  const data = {
    ...entity,
    id,
    createdAt: moment(),
  };

  return db
    .collection(model)
    .doc(id)
    .set(serialize(data))
    .then(() => data);
}

export function update(entity, params = { model: MODEL }) {
  const db = firebase.firestore();
  const { model } = params;

  const data = {
    ...entity,
    updatedAt: moment(),
  };

  return db
    .collection(model)
    .doc(entity.id)
    .set(serialize(data))
    .then(() => data);
}

export function remove(id, params = { model: MODEL }) {
  const db = firebase.firestore();
  const { model } = params;

  return db
    .collection(model)
    .doc(id)
    .delete();
}

export function uploadAvatar(id, data, method = 'web') {
  switch (method) {
    case 'cloudstorage':
      const ref = firebase
        .storage()
        .ref()
        .child(`images/avatars/${id}.jpg`);
      return ref.putString(data, 'data_url').then(params => {
        return params;
      });

    case 'web':
      return axios
        .post(
          'http://localhost:5000/levelupskill-fba1e/us-central1/uploadAvatar',
          // 'https://us-central1-levelupskill-fba1e.cloudfunctions.net/uploadAvatar',
          {
            id,
            data,
          }
        );

    default:
      return new Promise((_resolve, reject) => reject('Unknown upload method'));
  }
}

export function requestSignInLink(email) {
  const auth = firebase.auth();
  const actionCodeSettings = {
    url: 'https://levelupskill.com/login',
    handleCodeInApp: true,
  };
  return auth.sendSignInLinkToEmail(email, actionCodeSettings);
}

export function isUrlSignInLink(url) {
  const auth = firebase.auth();
  return auth.isSignInWithEmailLink(url);
}

export function signInWithLink(email, url) {
  const auth = firebase.auth();
  return auth.signInWithEmailLink(email, url);
}

export function signOut() {
  const auth = firebase.auth();
  return auth.signOut();
}

export function currentUser() {
  const auth = firebase.auth();
  return auth.currentUser;
}

export function doesEmailExist(email, params = { model: MODEL }) {
  const db = firebase.firestore();
  const { model } = params;
  return new Promise((resolve, reject) => {
    db.collection(model)
      .where('email', '==', email)
      .get()
      .then(user => {
        if (user.size > 0) {
          resolve(user.size);
        } else {
          reject(new APIError('emailNotFound', 'Email not found'));
        }
      })
      .catch(reject);
  });
}

export function userModel(model) {
  return {
    list: (callback, params) => list(callback, { ...params, model }),
    create: (entity, id, params) => create(entity, id, { ...params, model }),
    update: (entity, params) => update(entity, { ...params, model }),
    get: (id, callback, params) =>
      get(id, callback, (params = { ...params, model })),
    getOnceByEmail: (email, params) =>
      getOnceByEmail(email, (params = { ...params, model })),
    remove: (id, params) => remove(id, { ...params, model }),
    uploadAvatar: (id, dataUrl) => uploadAvatar(id, dataUrl),
    requestSignInLink: email => requestSignInLink(email),
    isUrlSignInLink: url => isUrlSignInLink(url),
    signInWithLink: (email, url) => signInWithLink(email, url),
    doesEmailExist: (email, params) =>
      doesEmailExist(email, (params = { ...params, model })),
    currentUser: () => currentUser(),
    signOut: () => signOut(),
  };
}

export default {
  list,
  create,
  update,
  get,
  remove,
  uploadAvatar,
  requestSignInLink,
  isUrlSignInLink,
  signInWithLink,
  currentUser,
  signOut,
  doesEmailExist,
};
