import React, { Fragment } from 'react';
import cx from 'classnames';
import { withTranslation } from 'react-i18next'; 

class SearchBox extends React.Component {
  static defaultProps = {
    collapsible: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeSearch: false,
    };
  }

  render() {
    const { collapsible, t } = this.props;
    const activeSearch = !collapsible || this.state.activeSearch;

    return (
      <Fragment>
        <div
          className={cx('search-wrapper', {
            active: activeSearch,
          })}
        >
          <div className="input-holder">
            <input
              type="text"
              className="search-input"
              placeholder={t('Type to search courses, students, and other stuffs...')}
            />
            <button
              onClick={() =>
                this.setState({ activeSearch: !activeSearch })
              }
              className="search-icon"
            >
              <span />
            </button>
          </div>
          {collapsible && (
            <button
              onClick={() =>
                this.setState({ activeSearch: !activeSearch })
              }
              className="close"
            />
          )}
        </div>
      </Fragment>
    );
  }
}

export default withTranslation()(SearchBox);
