import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

import LoadingIndicator from '../LoadingIndicator';

class PageLoading extends React.Component {
  render() {
    return (
      <div className="loader-container">
        <div className="loader-container-inner">
          <div className="text-center">
            <LoadingIndicator />
          </div>
          <h6 className="mt-5">
            <Trans>Please wait while we load this page...</Trans>
          </h6>
        </div>
      </div>
    );
  }
}

export default withTranslation()(PageLoading);