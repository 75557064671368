import React, { Suspense } from 'react';
import { withRouter, Route as DomRoute, Redirect } from 'react-router-dom';
import { isNil } from 'lodash';

import { withGlobalState } from '../../../services/GlobalState';
import PageLoading from '../../../components/PageLoading';

function withRoute(WrappedComponent) {
  class RoutedComponent extends React.Component {
    componentWillMount() {
      const { authenticated, globalState, history } = this.props;
      const isAuthenticated = !isNil(globalState.user);
      if (authenticated && !isAuthenticated) {
        history.push('/login');
      }
    }

    render() {
      const { authenticated, globalState } = this.props;
      const isAuthenticated = !isNil(globalState.user) && !isNil(globalState.user.id);
      if (authenticated && !isAuthenticated) {
        return <PageLoading />;
      }

      return <WrappedComponent />;
    }
  };

  return withRouter(withGlobalState(RoutedComponent));
}

class Route extends React.Component {
  constructor(props) {
    super(props);

    const { component } = props;
    if (!isNil(component)) {
      const RoutedComponent = withRoute(withRouter(component));

      this.state = {
        component: () => <RoutedComponent {...this.props} />
      };
    } else {
      this.state = {};
    }
  }

  render() {
    const { path, exact, redirectTo } = this.props;
    const { component } = this.state;

    if (isNil(component)) {
      return null;
    }

    if (redirectTo) {
      return (
        <DomRoute
          exact={exact}
          path={path}
          render={() => <Redirect to={redirectTo} />}
        />
      );
    }

    return (
      <Suspense fallback={<PageLoading />}>
        <DomRoute exact={exact} path={path} component={component} />
      </Suspense>
    );
  }
}

export default Route;
