import React, { createContext } from 'react';
import { isNil } from 'lodash';

const GlobalStateContext = createContext({
  user: null,
  alert: null,
  updateUser: () => {},
  logoutUser: () => {},
  setGlobalState: () => {},
  showAlert: (options) => {},
  hideAlert: () => {},
});

export class GlobalStateProvider extends React.Component {
  updateUser = user => {
    this.setState({ user }, () => {
      if (isNil(user)) {
        window.localStorage.removeItem('user');
      } else {
        window.localStorage.setItem('user', btoa(JSON.stringify(user)));
      }
    });
  };

  logoutUser = () => {
    this.updateUser();
  };

  showAlert = (options) => {
    this.setState({ alert: {
      ...options,
      visible: true,
    }});
  };

  hideAlert = () => {
    this.setState({ alert: {
      visible: false
    }});
  };

  state = {
    user: null,
    alert: {},
    updateUser: this.updateUser,
    logoutUser: this.logoutUser,
    setGlobalState: this.setState,
    showAlert: this.showAlert,
    hideAlert: this.hideAlert,
  };

  render() {
    return (
      <GlobalStateContext.Provider value={this.state}>
        {this.props.children}
      </GlobalStateContext.Provider>
    );
  }
}

export const GlobalStateConsumer = GlobalStateContext.Consumer;
export const withGlobalState = Component => {
  class ComponentWithGlobalState extends React.Component {
    render() {
      return (
        <GlobalStateContext.Consumer>
          {globalState => (
            <Component globalState={globalState} {...this.props} />
          )}
        </GlobalStateContext.Consumer>
      );
    }
  }

  return ComponentWithGlobalState;
};
