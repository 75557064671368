import React from 'react';
import ReactDOM from 'react-dom';
import { isNil } from 'lodash';
import { withTranslation } from 'react-i18next';
import SweetAlert from 'sweetalert-react';

import './style.scss';

class AlertModal extends React.Component {
  static defaultProps = {
    labels: {
      title: '',
      cancel: 'Cancel',
      ok: 'Ok',
    },
    containerId: 'root',
    isCancelVisible: true,
    isOkVisible: true,
  };

  handleClose = () => {
    const { onClose, data } = this.props;
    if (onClose) {
      onClose(data);
    } else {
      this.handleCancel();
    }
  };

  handleCancel = () => {
    const { onCancel, data } = this.props;
    if (onCancel) {
      onCancel(data);
    }
  };

  handleOk = () => {
    const { onOk, data } = this.props;
    if (onOk) {
      onOk(data);
    }
  };

  render() {
    const {
      t,
      visible,
      type,
      labels,
      children,
      containerId,
      isCancelVisible,
      isOkVisible,
    } = this.props;
    const modal = (
      <SweetAlert
        confirmButtonColor=""
        title={t(labels.title)}
        type={type}
        show={visible}
        text={t(children)}
        showCancelButton={isCancelVisible}
        showConfirmButton={isOkVisible}
        confirmButtonText={t(labels.ok)}
        cancelButtonText={t(labels.cancel)}
        onEscapeKey={this.handleClose}
        onConfirm={this.handleOk}
        onCancel={this.handleCancel}
      />
    );

    if (!isNil(containerId)) {
      return ReactDOM.createPortal(modal, document.getElementById(containerId));
    }

    return modal;
  }
}

const translatedComponent = withTranslation()(AlertModal);
translatedComponent.defaultProps = AlertModal.defaultProps;

export default translatedComponent;
