import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';

import './style.scss';

class AppFooter extends React.Component {
  render() {
    return (
      <Fragment>
        <div className="app-footer AppFooter">
          <div className="app-footer__inner">
            <div className="app-footer-left">
              Copyright @ {new Date().getFullYear()} LevelUpSkills. All Rights Reserved.
            </div>
            <div className="app-footer-right">
              <ul>
                <li><Link to="/privacy"><Trans>Privacy Policy</Trans></Link></li>
                <li><Link to="/terms"><Trans>Terms of Service</Trans></Link></li>
                <li><Link to="/legal"><Trans>Legal</Trans></Link></li>
                <li><Link to="/help"><Trans>Help</Trans></Link></li>
              </ul>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withTranslation()(AppFooter);
