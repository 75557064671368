import firebase from 'firebase';
import { v4 as uuid } from 'uuid';
import { isNil } from 'lodash';
import moment from 'moment';
import { serialize, deserialize } from '../../utils/db';

const MODEL = 'posts';

export function list(callback, params = { subscribe: false }) {
  const db = firebase.firestore();

  if (params.subscribe) {
    return db.collection(MODEL).onSnapshot(snapshot => {
      const results = snapshot.docs.map(doc => {
        return deserialize(doc.exists ? doc.data() : null);
      });
      callback(results);
    });
  } else {
    return db
      .collection(MODEL)
      .get()
      .then(snapshot => {
        const results = snapshot.docs.map(doc => {
          return deserialize(doc.exists ? doc.data() : null);
        });
        callback(results);
      });
  }
}

export function get(id, callback, params = { subscribe: false }) {
  const db = firebase.firestore();

  if (params.subscribe) {
    return db
      .collection(MODEL)
      .doc(id)
      .onSnapshot(doc => {
        const result = deserialize(doc.exists ? doc.data() : null);
        callback(result);
      });
  } else {
    return db
      .collection(MODEL)
      .doc(id)
      .get()
      .then(doc => {
        const result = deserialize(doc.exists ? doc.data() : null);
        callback(result);
      });
  }
}

export function create(entity, id = uuid()) {
  const db = firebase.firestore();
  return db
    .collection(MODEL)
    .doc(id)
    .set(
      serialize({
        ...entity,
        id,
      })
    );
}

export function update(entity) {
  const db = firebase.firestore();
  return db
    .collection(MODEL)
    .doc(entity.id)
    .set(serialize({
      ...entity,
      updatedAt: !isNil(entity.updatedAt)
        ? moment(entity.updatedAt).toISOString()
        : null
    }));
}

export function remove(id) {
  const db = firebase.firestore();
  return db
    .collection(MODEL)
    .doc(id)
    .delete();
}

export default {
  list,
  create,
  update,
  get,
  remove,
};
