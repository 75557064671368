import { lazy } from 'react';

const Login = lazy(() => import('./Login'));
const Register = lazy(() => import('./Register'));

const Dashboard = lazy(() => import('./Dashboard'));
const Courses = lazy(() => import('./Courses'));
const Guardians = lazy(() => import('./Guardians'));
const Students = lazy(() => import('./Students'));
const Instructors = lazy(() => import('./Instructors'));
const Administrators = lazy(() => import('./Administrators'));
const Profile = lazy(() => import('./Profile'));
const Inbox = lazy(() => import('./Inbox'));
const Chat = lazy(() => import('./Chat'));
const Timetable = lazy(() => import('./Timetable'));

const UserPages = lazy(() => import('./UserPages'));
const Applications = lazy(() => import('./Applications'));
const Dashboards = lazy(() => import('./Dashboards'));

const Widgets = lazy(() => import('./Widgets'));
const Elements = lazy(() => import('./Elements'));
const Components = lazy(() => import('./Components'));
const Charts = lazy(() => import('./Charts'));
const Forms = lazy(() => import('./Forms'));
const Tables = lazy(() => import('./Tables'));

export const Routes = {
  authenticated: {
    '/dashboard': { component: Dashboard },
    '/courses': { component: Courses },
    '/guardians': { component: Guardians },
    '/students': { component: Students },
    '/instructors': { component: Instructors },
    '/administrators': { component: Administrators },
    '/profile': { component: Profile },
    '/inbox': { component: Inbox },
    '/chat': { component: Chat },
    '/timetable': { component: Timetable },
    '/components': { component: Components },
    '/forms': { component: Forms },
    '/charts': { component: Charts },
    '/tables': { component: Tables },
    '/elements': { component: Elements },
    '/widgets': { component: Widgets },
    '/pages': { component: UserPages },
    '/apps': { component: Applications },
    '/dashboards': { component: Dashboards },
  },
  public: {
    '/login': { component: Login },
    '/register': { component: Register },
  },
};