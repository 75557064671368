import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

import { BrowserRouter as Router } from 'react-router-dom';
import './assets/base.scss';
import Main from './app/Main';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';

import './i18n';

const store = configureStore();
const rootElement = document.getElementById('root');

const renderApp = Component => {
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <Component />
      </Router>
    </Provider>,
    rootElement
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept('./app/Main', () => {
    const NextApp = require('./app/Main').default;
    renderApp(NextApp);
  });
}
serviceWorker.unregister();
