import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en_AU from './lang/en_AU.json';
import en_US from './lang/en_US.json';
import zh_HK from './lang/zh_HK.json';
import zh_CN from './lang/zh_CN.json';
import fr_FR from './lang/fr_FR.json';
import de_DE from './lang/de_DE.json';
import it_IT from './lang/it_IT.json';
import es_ES from './lang/es_ES.json';
import jp_JP from './lang/jp_JP.json';
import id_ID from './lang/id_ID.json';

export const resources = {
  'en_AU': { translation: en_AU, key: 'en_AU', label: 'English (AU)', flag: 'AU' },
  'en_US': { translation: en_US, key: 'en_US', label: 'English (US)', flag: 'US' },
  'zh_HK': { translation: zh_HK, key: 'zh_HK', label: '繁體中文', flag: 'HK' },
  'zh_CN': { translation: zh_CN, key: 'zh_CN', label: '简体中文', flag: 'CN' },
  'fr_FR': { translation: fr_FR, key: 'fr_FR', label: 'Française', flag: 'FR' },
  'de_DE': { translation: de_DE, key: 'de_DE', label: 'Deutsche', flag: 'DE' },
  'it_IT': { translation: it_IT, key: 'it_IT', label: 'Italiano', flag: 'IT' },
  'es_ES': { translation: es_ES, key: 'es_ES', label: 'Español', flag: 'ES' },
  'jp_JP': { translation: jp_JP, key: 'jp_JP', label: '日本語', flag: 'JP' },
  'id_ID': { translation: id_ID, key: 'id_ID', label: 'Bahasa Indonesia', flag: 'ID' },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en_AU',
  debug: false,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
