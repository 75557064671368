import React from 'react';
import cx from 'classnames';
import { getAvatarImage } from '../../utils';

import './style.scss';

export default class AvatarIcon extends React.Component {
  render() {
    const { user, className } = this.props;

    let avatarImage = getAvatarImage(user);
    let avatarIcon;
    if (avatarImage) {
      avatarIcon = <div className="imageDiv" style={{ backgroundImage: `url(${avatarImage})` }} />;
    } else {
      let iconInitials = '';
      if (user) {
        if (user.firstName) {
          iconInitials = `${iconInitials}${user.firstName[0]}`;
        }
        if (user.lastName) {
          iconInitials = `${iconInitials}${user.lastName[0]}`;
        }
      }
      avatarIcon = <span className="initials">{iconInitials}</span>;
    }

    return <div className={cx('AvatarIcon circle', className)}>{avatarIcon}</div>;
  }
}