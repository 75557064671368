import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

import './style.scss';

export default class PageNotFound extends React.Component {
  render() {
    const page = (
      <div className="PageNotFound">
        <h1>Looks like you're lost!</h1>
        <p>The page you are looking for is not available.</p>
        <Link to="/" className="btn btn-primary btn-pill btn-wide btn-shadow btn-hover-shine">Go to Homepage</Link>
      </div>
    );
    return ReactDOM.createPortal(page, document.getElementById('root'));
  }
}