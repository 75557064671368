import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

import { Nav, NavLink, NavItem, DropdownItem, Button } from 'reactstrap';

class InboxSidebar extends React.Component {
  render() {
    return (
      <div className="InboxSidebar app-inner-layout__sidebar">
        <Nav vertical>
          <NavItem className="pt-4 pl-3 pr-3 pb-3">
            <Button block color="primary" className="btn-pill btn-shadow">
              <Trans>Write New Message</Trans>
            </Button>
          </NavItem>
          <NavItem className="nav-item-header">
            <Trans>Messages</Trans>
          </NavItem>
          <NavItem>
            <NavLink>
              <i className="nav-link-icon pe-7s-chat"> </i>
              <span>
                <Trans>Unread Messages</Trans>
              </span>
              <div className="ml-auto badge badge-pill badge-info">8</div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink>
              <i className="nav-link-icon pe-7s-wallet"> </i>
              <span>
                <Trans>Sent Messages</Trans>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink>
              <i className="nav-link-icon pe-7s-config"> </i>
              <span>
                <Trans>All Messages</Trans>
              </span>
            </NavLink>
          </NavItem>
          <NavItem className="nav-item-divider" />
          <NavItem>
            <NavLink>
              <i className="nav-link-icon pe-7s-box2"> </i>
              <span>
                <Trans>Trash Bin</Trans>
              </span>
            </NavLink>
          </NavItem>
          <NavItem className="nav-item-divider" />
          <NavItem className="nav-item-header">
            <Trans>Tags</Trans>
          </NavItem>
          <NavItem>
            <DropdownItem className="d-flex align-items-center">
              <div className="badge ml-0 mr-3 badge-dot badge-dot-xl badge-success">
                Dark
              </div>
              <Trans>Important</Trans>
            </DropdownItem>
            <DropdownItem className="d-flex align-items-center">
              <div className="badge ml-0 mr-3 badge-dot badge-dot-xl badge-danger">
                Dark
              </div>
              <Trans>Favourites</Trans>
            </DropdownItem>
            <DropdownItem className="d-flex align-items-center">
              <div className="badge ml-0 mr-3 badge-dot badge-dot-xl badge-warning">
                Dark
              </div>
              <Trans>Actions</Trans>
            </DropdownItem>
          </NavItem>
        </Nav>
      </div>
    );
  }
}

export default withTranslation()(InboxSidebar);
