import React, { Component, Fragment } from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
import MetisMenu from 'react-metismenu';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { isEmpty } from 'lodash';

import { Button, Row, Col } from 'reactstrap';

import './style.scss';

import {
  ManageNav,
  MainNav,
  ComponentsNav,
  FormsNav,
  WidgetsNav,
  ChartsNav,
} from './NavItems';

import RouterLink from './RouterLink';
import InboxSidebar from '../../Inbox/InboxSidebar';
import ChatSidebar from '../../Chat/ChatSidebar';

function getPaths(items, paths = []) {
  if (isEmpty(items)) {
    return paths;
  }
  let foundPaths = [];
  items.forEach(item => {
    if (item.to) {
      foundPaths.push(item.to);
    } else {
      foundPaths = getPaths(item.content || [], foundPaths);
    }
  });
  return [...paths, ...foundPaths];
}

const manageMenuItems = [{ heading: 'Manage', nav: ManageNav, paths: getPaths(ManageNav) }];
const manageMenu = {
  paths: manageMenuItems.reduce((paths, i) => ([...i.paths, ...paths]), []),
  items: manageMenuItems,
};

const libraryMenuItems = [
  { heading: 'Menu', nav: MainNav, paths: getPaths(MainNav) },
  { heading: 'UI Components', nav: ComponentsNav, paths: getPaths(ComponentsNav) },
  { heading: 'Dashboard Widgets', nav: WidgetsNav, paths: getPaths(WidgetsNav) },
  { heading: 'Forms', nav: FormsNav, paths: getPaths(FormsNav) },
  { heading: 'Charts', nav: ChartsNav, paths: getPaths(ChartsNav) },
];
const libraryMenu = {
  paths: libraryMenuItems.reduce((paths, i) => ([...i.paths, ...paths]), []),
  items: libraryMenuItems,
};

const viewDefaultRoutes = ['/dashboard', '/inbox', '/chat', '/timetable', '/dashboards/analytics'];

class VerticalNav extends Component {
  state = {
    views: [true, false, false, false, false],
    pathname: '',
  };

  componentDidMount() {
    const { pathname } = window.location;
    if (pathname.indexOf('/inbox') === 0) {
      this.setState({ views: [false, true, false, false, false] });
    } else if (pathname.indexOf('/chat') === 0) {
      this.setState({ views: [false, false, true, false, false] });
    } else if (pathname.indexOf('/timetable') === 0) {
      this.setState({ views: [false, false, false, true, false] });
    } else if (libraryMenu.paths.find(path => pathname.includes(path))) {
      this.setState({ views: [false, false, false, false, true] });
    } else {
      this.setState({ views: [true, false, false, false, false] });
    }
  }

  componentDidUpdate() {
    const { pathname } = window.location;
    if (this.state.pathname !== pathname) {
      this.setState({ pathname });
    }
  }

  setView(viewIndex) {
    this.setState(prevState => {
      return {
        views: prevState.views.map((x, index) =>
          viewIndex === index
        ),
      };
    }, () => {
      this.props.history.push(viewDefaultRoutes[viewIndex]);
    });
  }

  getActiveLinkTo(pathname, menu) {
    const foundPath = menu.paths.find(path => pathname.includes(path));
    return foundPath;
  }

  render() {
    const { views, pathname } = this.state;

    return (
      <div className="VerticalNav">
        <PerfectScrollbar>
          <div className={cx('app-sidebar__inner', {
            manage: views[0],
            inbox: views[1],
            chat: views[2],
            timetable: views[3],
            library: views[4],
          })}>
            {views[0] &&
              manageMenu.items.map((menu, index) => (
                <Fragment key={index}>
                  <h5 className="app-sidebar__heading">
                    <Trans>{menu.heading}</Trans>
                  </h5>
                  <MetisMenu
                    key={`${pathname}_0`}
                    content={menu.nav}
                    activeLinkTo={this.getActiveLinkTo(pathname, menu)}
                    className="vertical-nav-menu"
                    iconNamePrefix=""
                    classNameStateIcon="pe-7s-angle-down"
                    LinkComponent={RouterLink}
                  />
                </Fragment>
              ))}
            {views[1] && <InboxSidebar />}
            {views[2] && <ChatSidebar />}
            {views[4] &&
              libraryMenu.items.map((menu, index) => (
                <Fragment key={index}>
                  <h5 className="app-sidebar__heading">
                    <Trans>{menu.heading}</Trans>
                  </h5>
                  <MetisMenu
                    key={`${pathname}_3`}
                    content={menu.nav}
                    activeLinkTo={this.getActiveLinkTo(pathname, menu)}
                    className="vertical-nav-menu"
                    iconNamePrefix=""
                    classNameStateIcon="pe-7s-angle-down"
                    LinkComponent={RouterLink}
                  />
                </Fragment>
              ))}
          </div>
        </PerfectScrollbar>
        <Row className="navGroupButtons no-gutters grid-menu">
          <Col>
            <Button
              className={cx('btn-icon-vertical btn-square btn-transition', { active: views[0] })}
              outline
              color={views[0] ? 'primary' : 'secondary'}
              onClick={() => this.setView(0)}
            >
              <i className="btn-icon-wrapper fas fa-tasks icon-gradient bg-tempting-azure"> </i>
              <span><Trans>Manage</Trans></span>
            </Button>
          </Col>
          <Col>
            <Button
              className={cx('btn-icon-vertical btn-square btn-transition', { active: views[1] })}
              outline
              color={views[1] ? 'primary' : 'secondary'}
              onClick={() => this.setView(1)}
            >
              <i className="btn-icon-wrapper fas fa-inbox icon-gradient bg-sunny-morning"> </i>
              <span><Trans>Inbox</Trans></span>
            </Button>
          </Col>
          <Col>
            <Button
              className={cx('btn-icon-vertical btn-square btn-transition', { active: views[2] })}
              outline
              color={views[2] ? 'primary' : 'secondary'}
              onClick={() => this.setView(2)}
            >
              <i className="btn-icon-wrapper fas fa-comments-alt icon-gradient bg-love-kiss"> </i>
              <span><Trans>Chat</Trans></span>
            </Button>
          </Col>
          <Col>
            <Button
              className={cx('btn-icon-vertical btn-square btn-transition', { active: views[3] })}
              outline
              color={views[3] ? 'primary' : 'secondary'}
              onClick={() => this.setView(3)}
            >
              <i className="btn-icon-wrapper fas fa-calendar-alt icon-gradient bg-malibu-beach"> </i>
              <span><Trans>Timetable</Trans></span>
            </Button>
          </Col>
          <Col>
            <Button
              className={cx('btn-icon-vertical btn-square btn-transition', { active: views[4] })}
              outline
              color={views[4] ? 'primary' : 'secondary'}
              onClick={() => this.setView(4)}
            >
              <i className="btn-icon-wrapper fas fa-book icon-gradient bg-ripe-malin"> </i>
              <span><Trans>Library</Trans></span>
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}

export default withTranslation()(withRouter(VerticalNav));
