/* eslint-disable react/jsx-no-duplicate-props */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import firebase from 'firebase';
import { isNil } from 'lodash';

import ResizeDetector from 'react-resize-detector';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import { Routes } from '../routes';

import AppHeader from '../Layout/AppHeader';
import AppSidebar from '../Layout/AppSidebar';
import AppFooter from '../Layout/AppFooter';
import AppMain from '../Layout/AppMain';
import AppAuth from '../Layout/AppAuth';
import PageLoading from '../../components/PageLoading';

import PageNotFound from '../PageNotFound';

import {
  GlobalStateProvider,
  GlobalStateConsumer,
} from '../../services/GlobalState';
import { APIProvider, APIConsumer } from '../../services/API';

import AlertModal from '../../components/AlertModal';

const firebaseProjectId = 'levelupskill-fba1e';
const firebaseConfig = {
  apiKey: 'AIzaSyCEMpnmkQyVbHZWY9GVnT8_WaPIuOpm2g4',
  authDomain: `${firebaseProjectId}.firebaseapp.com`,
  apiURL: `https://${firebaseProjectId}.firebaseio.com`,
  projectId: firebaseProjectId,
  storageBucket: `${firebaseProjectId}.appspot.com`,
  appID: 'levelupskill-web',
};

const routes = Object.keys(Routes.authenticated).concat(Object.keys(Routes.public));

class Main extends React.Component {
  constructor(props) {
    super(props);

    const firebaseInstance = firebase.initializeApp(firebaseConfig);
    
    firebase.auth().onAuthStateChanged(async userData => {
      const isAuthenticated = !isNil(userData);
      let user;

      if (isAuthenticated) {
        const { email } = userData;
        user = { email };
        
        this.api.administrator
          .getOnceByEmail(email)
          .then(userData => {
            this.globalState.updateUser(userData);
            this.setState({ user: userData });
          });
      }

      this.globalState.updateUser(user);
      this.setState({ user, isAuthenticated, isAuthenticating: false });

      if (isAuthenticated) {
        const { history } = this.props;
        if (window.location.href.includes('login')) {
          history.push('/');
        }
      }
    });

    this.state = {
      closedSmallerSidebar: false,
      isAuthenticating: true,
      firebaseInstance,
    };
  }

  handleUserChatClick = () => {
    this.props.history.push('/chat');
  };

  handleUserMessagesClick = () => {
    this.props.history.push('/inbox');
  };

  handleUserTasksClick = () => {
    this.props.history.push('/tasks');
  };

  handleUserLogoutClick = () => {
    const { showAlert } = this.globalState;
    showAlert({
      type: 'info',
      title: 'Confirm Sign Out',
      message: 'Are you sure you want to sign out?',
      ok: 'Yes',
      cancel: 'No',
      onOk: () => {
        this.api.administrator.signOut().then(() => {
          this.globalState.logoutUser();
          this.props.history.push('/login');
        });
      },
    });
  };

  handleUserProfileClick = () => {
    this.props.history.push('/profile');
  };

  renderAlertModal(globalState) {
    const { alert, hideAlert } = globalState;

    return (
      <AlertModal
        visible={alert.visible}
        type={alert.type}
        labels={{
          title: alert.title,
          cancel: alert.cancel || AlertModal.defaultProps.labels.cancel,
          ok: alert.ok || AlertModal.defaultProps.labels.ok,
        }}
        isOkVisible={alert.isOkVisible}
        isCancelVisible={alert.isCancelVisible}
        onClose={async () => {
          if (alert.onClose) {
            await alert.onClose();
          } else if (alert.onCancel) {
            await alert.onCancel();
          }
          hideAlert();
        }}
        onCancel={async () => {
          if (alert.onCancel) {
            await alert.onCancel();
          }
          hideAlert();
        }}
        onOk={async () => {
          if (alert.onOk) {
            await alert.onOk();
          }
          hideAlert();
        }}
      >
        {alert.message}
      </AlertModal>
    );
  }

  renderUnauthenticated(globalState) {
    const {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      enablePageTabsAlt,
    } = this.props;

    return (
      <ResizeDetector
        handleWidth
        render={({ width }) => (
          <Fragment>
            <div
              className={cx(
                'app-container app-theme-' + colorScheme,
                { 'fixed-header': enableFixedHeader },
                { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
                { 'fixed-footer': enableFixedFooter },
                { 'closed-sidebar': enableClosedSidebar || width < 1250 },
                {
                  'closed-sidebar-mobile': closedSmallerSidebar || width < 1250,
                },
                { 'sidebar-mobile-open': enableMobileMenu },
                { 'body-tabs-shadow-btn': enablePageTabsAlt }
              )}
            >
              <AppAuth />
            </div>
            {this.renderAlertModal(globalState)}
          </Fragment>
        )}
      />
    );
  }

  renderAuthenticated(globalState) {
    const { user } = globalState;

    const {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      enablePageTabsAlt,
    } = this.props;

    return (
      <ResizeDetector
        handleWidth
        render={({ width }) => (
          <Fragment>
            <div
              className={cx(
                'app-container app-theme-' + colorScheme,
                { 'fixed-header': enableFixedHeader },
                { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
                { 'fixed-footer': enableFixedFooter },
                { 'closed-sidebar': enableClosedSidebar || width < 1250 },
                {
                  'closed-sidebar-mobile': closedSmallerSidebar || width < 1250,
                },
                { 'sidebar-mobile-open': enableMobileMenu },
                { 'body-tabs-shadow-btn': enablePageTabsAlt }
              )}
            >
              <AppHeader
                user={user}
                onUserChatClick={this.handleUserChatClick}
                onUserMessagesClick={this.handleUserMessagesClick}
                onUserTasksClick={this.handleUserTasksClick}
                onUserLogoutClick={this.handleUserLogoutClick}
                onUserProfileClick={this.handleUserProfileClick}
              />
              <div className="app-main">
                <AppSidebar />
                <div className="app-main__outer">
                  <div className="app-main__inner">
                    <ReactCSSTransitionGroup
                      component="div"
                      transitionName="TabsAnimation"
                      transitionAppear={true}
                      transitionAppearTimeout={0}
                      transitionEnter={false}
                      transitionLeave={false}
                    >
                      <AppMain />
                    </ReactCSSTransitionGroup>
                  </div>
                  <AppFooter />
                </div>
              </div>
            </div>
            {this.renderAlertModal(globalState)}
          </Fragment>
        )}
      />
    );
  }

  render() {
    const { isAuthenticating, firebaseInstance } = this.state;
    const needsAuthentication = ['/login', '/register'].every(
      fragment => !window.location.href.includes(fragment)
    );

    return (
      <div className="App">
        <GlobalStateProvider>
          <GlobalStateConsumer>
            {globalState => {
              this.globalState = globalState;

              return (
                <APIProvider firebase={firebaseInstance}>
                  <APIConsumer>
                    {api => {
                      this.api = api;

                      const { pathname } = window.location;
                      if (pathname !== '/' && !routes.find(route => pathname.indexOf(route) === 0)) {
                        return <PageNotFound />;
                      }

                      if (isAuthenticating) {
                        return <PageLoading />;
                      }

                      return needsAuthentication
                        ? this.renderAuthenticated(globalState)
                        : this.renderUnauthenticated(globalState);
                    }}
                  </APIConsumer>
                </APIProvider>
              );
            }}
          </GlobalStateConsumer>
        </GlobalStateProvider>
      </div>
    );
  }
}

const mapStateToProp = state => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default withRouter(connect(mapStateToProp)(Main));
