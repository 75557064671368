import React, { Fragment } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { isNil } from 'lodash';

import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

import city3 from '../../../../assets/utils/images/dropdown-header/city3.jpg';

import Button from '../../../../components/Button';
import AvatarIcon from '../../../../components/AvatarIcon';

class UserBox extends React.Component {
  state = {
    isDropdownOpen: false,
  };

  handleToggleDropdown = () => {
    this.setState(state => ({ isDropdownOpen: !state.isDropdownOpen }));
  }

  handleAction = (callback) => {
    this.setState({ isDropdownOpen: false }, () => {
      if (callback) {
        callback();
      }
    });
  }

  render() {
    const {
      user,
      onChatClick,
      onMessagesClick,
      onTasksClick,
      onLogoutClick,
      onProfileClick,
    } = this.props;

    const { isDropdownOpen } = this.state;

    if (isNil(user)) {
      return null;
    }

    const { firstName, lastName } = user;
    let { displayName } = user;
    if (!displayName) {
      displayName = firstName && lastName ? `${firstName} ${lastName}` : null;
    }

    return (
      <Fragment>
        <div className="header-btn-lg pr-0">
          <div className="widget-content p-0">
            <div className="widget-content-wrapper">
              <div className="widget-content-left">
                <Dropdown isOpen={isDropdownOpen} toggle={this.handleToggleDropdown}>
                  <DropdownToggle color="link" className="p-0 profileButton">
                    <AvatarIcon user={user} />
                    <FontAwesomeIcon
                      className="ml-2 opacity-8"
                      icon={faAngleDown}
                    />
                  </DropdownToggle>
                  <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                    <div className="dropdown-menu-header">
                      <div className="dropdown-menu-header-inner bg-info">
                        <div
                          className="menu-header-image opacity-2"
                          style={{
                            backgroundImage: 'url(' + city3 + ')',
                          }}
                        />
                        <div className="menu-header-content text-left">
                          <div className="widget-content p-0">
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left mr-3">
                                <AvatarIcon user={user} />
                              </div>
                              <div className="widget-content-left">
                                <div className="widget-heading">
                                  {displayName || <em><Trans>Unknown name</Trans></em>}
                                </div>
                                <div className="widget-subheading opacity-8">
                                  {user.email || <em><Trans>Unknown email</Trans></em>}
                                </div>
                              </div>
                              <div className="widget-content-right mr-2">
                                <Button color="focus" onClick={() => this.handleAction(onLogoutClick)}>
                                  <Trans>Sign out</Trans>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Nav vertical>
                      <NavItem>
                        <NavLink onClick={() => this.handleAction(onMessagesClick)}>
                          <Trans>Inbox</Trans>
                          <div className="ml-auto badge badge-pill badge-info">
                            8
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink onClick={() => this.handleAction(onChatClick)}>
                          <Trans>Chat</Trans>
                          <div className="ml-auto badge badge-pill badge-warning">
                            256
                          </div>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink onClick={() => this.handleAction(onTasksClick)}>
                          <Trans>Tasks</Trans>
                          <div className="ml-auto badge badge-pill badge-warning">
                            512
                          </div>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <Nav vertical>
                      <NavItem className="nav-item-divider" />
                      <NavItem className="nav-item-btn text-center">
                        <Button type="primary" onClick={() => this.handleAction(onProfileClick)}>
                          <Trans>View Profile</Trans>
                        </Button>
                      </NavItem>
                    </Nav>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withTranslation()(UserBox);
