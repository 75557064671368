import React, { createContext } from 'react';

import { userModel } from './user';
import course from './course';
import post from './post';
import ref from './ref';

const APIContext = createContext({
  guardian: userModel('guardians'),
  student: userModel('students'),
  instructor: userModel('instructors'),
  administrator: userModel('administrators'),
  course,
  post,
  ref,
});

export class APIProvider extends React.Component {
  static instance;

  state = {
    guardian: userModel('guardians'),
    student: userModel('students'),
    instructor: userModel('instructors'),
    administrator: userModel('administrators'),
    course,
    post,
    ref,
  };

  componentWillMount() {
    if (!APIProvider.instance) {
      APIProvider.instance = true;
      ref.seed();
    }
  }

  render() {
    return (
      <APIContext.Provider value={this.state}>
        {this.props.children}
      </APIContext.Provider>
    );
  }
}

export const APIConsumer = APIContext.Consumer;
export const withAPI = Component => {
  class ComponentWithAPI extends React.Component {
    render() {
      return (
        <APIContext.Consumer>
          {api => <Component api={api} {...this.props} />}
        </APIContext.Consumer>
      );
    }
  }

  return ComponentWithAPI;
};
